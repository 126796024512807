import { createTheme } from '@mui/material/styles';

import { baseTheme } from './base';

export const typography = createTheme({
  typography: {
    fontSize: 16,
    fontFamily: 'Open Sans, sans-serif',
    h1: {
      fontFamily: 'Lora, serif',
      fontSize: '60px',
      lineHeight: '72px',
      fontWeight: 700,
      [baseTheme.breakpoints.down('lg')]: {
        fontSize: '40px',
        lineHeight: '48px',
      },
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '36px',
        lineHeight: '48px',
      },
    },
    h2: {
      fontFamily: 'Lora, serif',
      fontSize: '40px',
      lineHeight: '51px',
      fontWeight: 700,
      [baseTheme.breakpoints.down('lg')]: {
        fontSize: '28px',
        lineHeight: '48px',
      },
    },
    h3: {
      fontFamily: 'Lora, serif',
      fontSize: '26px',
      lineHeight: '33px',
      fontWeight: 700,
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: '23px',
      lineHeight: '31px',
    },
    body1: {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '34px',
    },
    body2: {
      fontWeight: 400,
      fontSize: '26px',
      lineHeight: '44px',
      [baseTheme.breakpoints.down('md')]: {
        fontSize: '22px',
        lineHeight: '38px',
      },
    },
    button: {
      fontWeight: 800,
    },
  },
}).typography;
