import { createTheme } from '@mui/material/styles';
// Setup theme allows to be imported at other places
export const baseTheme = createTheme({
  palette: {
    global01: '#233B76',
    global02: '#0066FE',
    neutral01: '#505D80',
    neutral02: '#F0F6FF',
    primary: {
      main: '#233B76',
    },
    text: {
      primary: '#505D80',
      secondary: '#233B76',
    },
  },
});
