import { EmotionCache } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import type { AppProps } from 'next/app';

import { IntlProvider } from 'services/i18n';
import ThemeContext from 'services/theme-context';

import { Head } from 'components/@common';
import { PageContainer } from 'components/@screens';

type PageProps = {};

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  pageProps: PageProps;
}

function MyApp(props: MyAppProps) {
  const { Component } = props;

  return (
    <ThemeContext>
      <CssBaseline />
      <IntlProvider>
        <PageContainer>
          <Head />
          <Component {...props} />
        </PageContainer>
      </IntlProvider>
    </ThemeContext>
  );
}
export default MyApp;
