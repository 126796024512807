import { ReactNode } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import { intl } from './config';

interface Props {
  children: ReactNode;
}

const IntlProvider = ({ children }: Props) => {
  return (
    <ReactIntlProvider locale={'nl'} messages={intl['nl'].messages}>
      {children}
    </ReactIntlProvider>
  );
};

export default IntlProvider;
