import { Components } from '@mui/material';

export const MuiCssBaseline: Components['MuiCssBaseline'] = {
  styleOverrides: `
    html {
      scroll-behavior: smooth;
    },
    address {
      font-style: normal;
    }
  `,
};
