import { Components, Theme } from '@mui/material';

export const MuiAppBar: Components<Theme>['MuiAppBar'] = {
  defaultProps: {
    elevation: 2,
  },
  styleOverrides: {
    root: {
      borderRadius: 0,
      height: 74,
      backgroundColor: 'white',
    },
  },
};
