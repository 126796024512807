import { Components, darken, Theme } from '@mui/material';

export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    color: 'primary',
    variant: 'contained',
  },
  styleOverrides: {
    containedPrimary: ({ theme }) => ({
      height: 50,
      textTransform: 'none',
      backgroundColor: theme.palette.global02,
      borderRadius: '0px 10px 10px 10px',
      transition: 'background-color 200ms ease-in',
      '&:hover': {
        backgroundColor: darken(theme.palette.global02, 0.3),
        '@media (hover: none)': {
          backgroundColor: theme.palette.global02,
        },
      },
    }),
  },
};
