import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/nl';

import { createIntl, createIntlCache, IntlShape } from 'react-intl';

import MESSAGES_NL from './translations/nl.json';

const intlCache = createIntlCache();

const intl: { [key: string]: IntlShape } = {
  nl: createIntl({ locale: 'nl', messages: MESSAGES_NL }, intlCache),
};

export { intl };
