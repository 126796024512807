import { DefaultSeo } from 'next-seo';
import { useIntl } from 'react-intl';

const Head = () => {
  const intl = useIntl();
  return (
    <>
      <DefaultSeo
        title={intl.formatMessage({ id: 'seo.title' })}
        description={intl.formatMessage({ id: 'seo.description' })}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/favicon.ico',
          },
          {
            rel: 'icon',
            href: '/favicon-16x16.png',
            sizes: '16x16',
          },
          {
            rel: 'icon',
            href: '/favicon-32x32.png',
            sizes: '32x32',
          },
          {
            rel: 'apple-touch-icon',
            href: '/assets/apple-touch-icon.png',
            sizes: '180x180',
          },
          {
            rel: 'manifest',
            href: '/manifest.json',
          },
        ]}
        openGraph={{
          title: intl.formatMessage({ id: 'seo.title' }),
          description: intl.formatMessage({ id: 'seo.description' }),
          site_name: intl.formatMessage({ id: 'seo.title' }),
          type: 'website',
          images: [
            {
              url: 'https://kinevanmoer.be/assets/og-share.jpg',
              width: 1200,
              height: 630,
            },
          ],
        }}
      />
    </>
  );
};

export default Head;
