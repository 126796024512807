import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const PageContainer = ({ children }: Props) => {
  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <Box
        component="main"
        flex="1 auto"
        bgcolor="common.white"
        color="common.black"
        display="flex"
        flexDirection="column"
      >
        {children}
      </Box>
    </Box>
  );
};

export default PageContainer;
